import React from "react"

import Layout from "../components/layout"
import Title from "../components/title"

import * as styles from "./list.module.scss";

const testData = [
  { id: 1, name: "Max Mustermann 1", avatar_url: "../images/200_100.png", company: "@Muster-Company 1"},
  { id: 2, name: "Max Mustermann 2", avatar_url: "../images/200_100.png", company: "@Muster-Company 2"},
  { id: 3, name: "Max Mustermann 3", avatar_url: "../images/200_100.png", company: "@Muster-Company 3"},
  { id: 4, name: "Max Mustermann 4", avatar_url: "../images/200_100.png", company: "@Muster-Company 4"},
  { id: 5, name: "Max Mustermann 5", avatar_url: "../images/200_100.png", company: "@Muster-Company 5"},
  { id: 6, name: "Max Mustermann 6", avatar_url: "../images/200_100.png", company: "@Muster-Company 6"},
  { id: 7, name: "Max Mustermann 7", avatar_url: "../images/200_100.png", company: "@Muster-Company 7"},
  { id: 8, name: "Max Mustermann 8", avatar_url: "../images/200_100.png", company: "@Muster-Company 8"},
  { id: 9, name: "Max Mustermann 9", avatar_url: "../images/200_100.png", company: "@Muster-Company 9"},
  { id: 10, name: "Max Mustermann 10", avatar_url: "../images/200_100.png", company: "@Muster-Company 10"},

];

class Card extends React.Component {
	render() {
  	return (
        <div className={styles.githubProfile} style={{ visibility: this.props.id < 10 ? 'visible' : 'hidden' }}>
          <img src={this.props.avatar_url} />
          <div className={styles.info}>
            <div className={styles.name}>{this.props.name}</div>
            <div className={styles.company}>{this.props.company}</div>
          </div>
    	  </div>
    );
  }
}

const Test = () => {
  return (
    <Layout>
      <Title text="RESULT SET" subtitle="" />
      <div>
        {testData.map(i => (
           <Card key={i.id} {...i} /> 
          )
        )}
      </div>
    </Layout>
  )
}

export default Test;